import React, { Component } from "react";
import SemiCircle from "../charts/semiCircle";
import LineChart from "../charts/lineChart";

class Fees extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    //BIND EVENTS
  }

  render() {
    const { reports } = this.props.graphData;
    return (
      <section className="section animate-bottom mb-0 px-0">
        <div className="container">
          {/* <h4 className="text-center">
          {reports.map(item => item.fees_headline)}
          </h4> */}
          <div className="row mb-4">
            <div className="col-12 col-lg-6 text-center">

              {reports.map((item) => (
                <SemiCircle
                  key={item.fees_monthly_dues_scaled_size}
                  dataSeries={[
                    item.fees_monthly_dues_scaled_size,
                    item.fees_monthly_dues_scaled_size_bm_min,
                    item.fees_monthly_dues_scaled_size_bm_max,
                    item.fees_monthly_dues_scaled_size_bm,
                    1
                  ]}
                />
              ))}
              <div className="chart-bottom-text">
              <p className="mb-3" style={{ fontWeight: '600', fontSize: '60px' , margin: '-120px' }}>${reports.map(item =>
                      item.fees_monthly_dues_scaled_size
                    )}</p>
                <p
                  className="mb-0 mt-3 pt-1"
                  style={{ fontWeight: "600", fontSize: "14px" }}
                >
                  {reports.map(
                    (item) => item.fees_monthly_dues_scaled_size_grade
                  )}
                </p>
                {reports.map((item) =>
                  item.fees_monthly_dues_scaled_size_comment!=null?item.fees_monthly_dues_scaled_size_comment
                    .split("\n")
                    .map((t, index) => {
                      return (
                        <p className="mt-5" key={index}>
                          {t}
                        </p>
                      );
                    }):''
                )}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              {/* <h2 className="text-center">Good to know</h2> */}
              <h4> {reports.map(item => item.fees_headline)}</h4>
              <ul className="list-unstyled mt-4 mb-0">
                {reports.map((item) =>
                  item.fees_highlights!=null?item.fees_highlights.split("\n").map((t, index) => {
                    return <li key={index}>{t}</li>;
                  }):''
                )}
              </ul>
            </div>
          </div>
          <div className="row mb-4 mt-5 fees-projection">
            {/* <div className="col-12 col-md-6 text-center">
              <h3 className="font-weight-normal">
                HOA Fees adjusted by market value
              </h3>
              {reports.map((item) => (
                <SemiCircle
                  key={1}
                  dataSeries={[
                    item.fees_monthly_dues_scaled_mv,
                    item.fees_monthly_dues_scaled_mv_bm,
                  ]}
                />
              ))}
              <div className="chart-bottom-text">
                <p
                  className="mb-0"
                  style={{ fontWeight: "600", fontSize: "20px" }}
                >
                  {reports.map(
                    (item) => item.fees_monthly_dues_scaled_mv_grade
                  )}
                </p>
                {reports.map((item) =>
                  item.fees_monthly_dues_scaled_mv_comment
                    .split("\n")
                    .map((t, index) => {
                      return (
                        <p className="mb-0" key={index}>
                          {t}
                        </p>
                      );
                    })
                )}
              </div>
            </div> */}
            <div className="col-12 col-md-12 text-center">
              <h3>HOA Fees Projection</h3>
              {reports.map((item) => (
                <LineChart
                  key={5}
                  dataSeries={[
                    { name: "Good Case", data: [item.fees_monthly_dues_scaled_size, Math.ceil(item.fees_monthly_dues_scaled_size * Math.pow((1 + item.fees_projection - 0.01), 5)), Math.ceil(item.fees_monthly_dues_scaled_size * Math.pow((1 + item.fees_projection - 0.01), 10))] },
                    { name: "Base Case", data: [item.fees_monthly_dues_scaled_size, Math.ceil(item.fees_monthly_dues_scaled_size * Math.pow((1 + item.fees_projection), 5)), Math.ceil(item.fees_monthly_dues_scaled_size * Math.pow((1 + item.fees_projection), 10))] },
                    { name: "Bad Case", data: [item.fees_monthly_dues_scaled_size, Math.ceil(item.fees_monthly_dues_scaled_size * Math.pow((1 + item.fees_projection + 0.01), 5)), Math.ceil(item.fees_monthly_dues_scaled_size * Math.pow((1 + item.fees_projection + 0.01), 10))] },
                  ]}
                  dataCategories={["2020", "2025", "2030"]}
                />
              ))}
            </div>
          </div>

        </div>
      </section>
    );
  }
}

export default Fees;
