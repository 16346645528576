import React, { Component } from 'react';
//import ReactApexChart from 'react-apexcharts';
import ReactSpeedometer from "react-d3-speedometer"
// and just use it


class SemiCircle extends Component {
  constructor(props) {
    super(props);
    this.getSegments = this.getSegments.bind(this);
  }

  getSegments = (val) => {
    //console.log(val[0],val[1],val[2])
    let ceilValue =100
    let minValue=0
    let actualValue=val[0]
    let avg = 0
    let color = 0 //0- green 1- yellow 2-red
    let flip = 0  //higher is worse
    //console.log(val.length)
    if (val.length===1) {
      ceilValue = val[0]>999?(Math.ceil(val[0] / 1000) * 1000):(Math.ceil(val[0] / 100) * 100);
    }else{
      //console.log("vishrut")
      //console.log((Math.ceil(val[1] / 10) * 10))

      // ceilValue=  val[2]>999?(Math.ceil(val[2] / 1000) * 1000):(Math.ceil(val[2] / 10) * 10);
      // minValue= val[1]>999?(Math.floor(val[1] / 1000) * 1000):(Math.floor(val[1] / 10) * 10);
      
      ceilValue=  val[2]>999?(Math.ceil(val[2] / 1000) * 1000):(Math.ceil(val[2] / 100) * 100);
      minValue= val[1]>999?(Math.floor(val[1] / 1000) * 1000):(Math.floor(val[1] / 100) * 100);

      //console.log(ceilValue, minValue,val[3])
      avg = val[3]
      flip =val[4]
      if (actualValue > .8*avg & actualValue < 1.2*  avg ){
        color=1
      }
      //more than avg
      else if (actualValue >=1.2*avg){
        //higher is worse
        if (val[4]==1) {
          color=2
        }
        else {
          color = 1
        }
      }
      //less than avg
      else if (actualValue <=.8*avg){
        //higher is worse
        if (val[4]==1) {
          color=1
        }
        else {
          color = 2
        }
      }
    }

    // if(ceilValue>1000 && (ceilValue-val)<500){
    //   ceilValue+=500;
    // }
    // else if(ceilValue<=1000 && ceilValue>100 && (ceilValue-val)<100){
    //   ceilValue+=100;
    // }
    // else if(ceilValue<=100 && (ceilValue-val)<10){
    //   ceilValue+=50;
    // }
  

    const part = Math.ceil((ceilValue-minValue) / 50)  ;
    let segments = [];
    let segColors = [];
    let segmentLabels=[]
    let rgb1=0;
    let rgb2=175;
    let rgb3=80;
    let label_brk= ((ceilValue-minValue) / 5).toFixed(1)

    let ctr=0 

    //red
    if (color===2){
      rgb1= 240+45
      rgb2= 161-30
      rgb3= 161-30
          
 
    }
    //yellow
    if (color===1){
      rgb1= 232
      rgb2= 229
      rgb3= 26
      rgb1=0;
      rgb2=175;
      rgb3=80;
 
    }

      for (let i = minValue; i <= ceilValue; i += part) {
        //console.log(i)
 
        segments.push(i);
      
        
        if (i<ceilValue  ){
          if (ctr >= label_brk){

            ctr=0
            segmentLabels.push( {
              text: ''+i,
              position: "OUTSIDE",
              // color: "#555",
            })
          }
          else {
            segmentLabels.push( {
              text: '',
              position: "INSIDE",
              // color: "#555",
            })
          }

          
        }
        ctr=ctr+part
        
        if (i>actualValue){
          segColors.push('rgb(223,227,230)');
        }
        else{
          segColors.push('rgb('+rgb1+','+rgb2+','+rgb3+')');
        }
          
      
    }
    return { _segments: segments, _segmentLabels: segmentLabels, _minValue: minValue, _maxValue: ceilValue, _segColors: segColors };
  }
  render() {
    const value = this.props.dataSeries;
    if (value[0]==0){
      value[0]=-1
    }

    const { _segments,_segmentLabels, _minValue, _maxValue, _segColors } = this.getSegments(value);
 
    return (
      <div id="chart" className="semi-circle-responsive">
        {/* <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={350} /> */}
         <ReactSpeedometer
          maxSegmentLabels ={0}
          fluidWidth={true}
          ringWidth={14}
          needleHeightRatio={0}
          needleColor="transparent"
          maxValue={_maxValue}
          minValue={_minValue}
          valueFormat={"d"}
          
          customSegmentStops={_segments}
          customSegmentLabels	={_segmentLabels}
          segmentColors={_segColors}
          value={   value[0]  }
          textColor={'black'}
          labelFontSize={"14px"}
          valueTextFontSize={"0px"}
          paddingHorizontal={0}
          
          
          
     
        />
      </div>
    );
  }
}
export default SemiCircle;