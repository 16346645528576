import React, { Component } from 'react';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <header>
          <nav className="navbar navbar-expand-md">
            <div className="container">

           
              <a className="navbar-brand" href="#">
                <img src="https://inspecthoa.com/wp-content/uploads/2020/10/logo_hoa.svg" alt="logo"/>
              </a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span className="navbar-toggler-icon">
                <i className="fa fa-bars" aria-hidden="true"></i>
                </span>
              </button>
              <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                <ul className="navbar-nav w-100 justify-content-center">
                  <li className="nav-item active">
                    <a className="nav-link" href="https://inspecthoa.com/">Home</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://inspecthoa.com/how-it-works/">How it works</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://inspecthoa.com/faq/">FAQ</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://inspecthoa.com/blog/">Blog</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://inspecthoa.com/about-us/">About Us</a>
                  </li>
                  <li className="nav-item">
                   
                  </li>
                </ul>
                <button className="btn head-btn">
                      Order Now
                  </button>
              </div>
            </div>
          </nav>
        </header>
    );
  }
}

export default (Header);
