import React, { Component } from 'react';
import { connect } from 'react-redux';
//import logo from './logo.svg';
import Header from './components/header';
import Footer from './components/footer';
//import PageNotFound from "./components/pageNotFound";
import Main from "./components/main";
import Main_free_version from "./components/main_free_version";
import NoDataAlert from './components/models/noDataAlert';
import './App.css';

class App extends Component {
  render() {
    const { location } = this.props;
    if (location.pathname === '/') {
      return (<NoDataAlert errorMessage={'No Address Found!'} />);
    }
	if (location.pathname.startsWith('/details')){
		return (
      <React.Fragment>
        <main>
        {
          location.pathname.includes('no_header') === false ?  <Header history={this.props.history} validatorReducer={this.props.validatorReducer} id="top" /> : ''
        }
         
          <div className="content-panel">
          <Main_free_version propertName={location.pathname.replace('/no_header','')} />
          </div>


          {location.pathname.includes('no_header') === false ?    <Footer></Footer>: ''}
  
        </main>
      </React.Fragment>
    );	
	}else{
		return (
      <React.Fragment>
        <main>
        {
          location.pathname.includes('no_header') === false ?  <Header history={this.props.history} validatorReducer={this.props.validatorReducer} id="top" /> : ''
        }
         
          <div className="content-panel">
          <Main propertName={location.pathname.replace('/no_header','')} />
          </div>


          {location.pathname.includes('no_header') === false ?    <Footer></Footer>: ''}
  
        </main>
      </React.Fragment>
    );
	}
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(App);
