import React, { Component } from 'react';

class OperationsSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        //BIND EVENTS
    }

    render() {
        const { reports } = this.props.graphData;
        console.log(reports)
        return (
            <section className="section animate-bottom mb-0 my-0 px-0 pb-0">
                <div className="container">
                    <h4 className="text-center">
                        {/* {reports.map(item => item.operations_headline)} */}
                    Operations Summary
                     </h4>
                    <div className="row mb-5">
                        <div className="col-12 col-sm-4 text-center">
                            <div className="icon-box-text">
                                <img src={require('../../icons/finacials-green.svg')} />
                                <p> {reports.map(item =>
                                    item.operations_highlights!=null?item.operations_highlights.split("\n")[0]:'')
                                }</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 text-center">
                            <div className="icon-box-text">
                                <img src={require('../../icons/bylaws-green.svg')} />
                                <p> {reports.map(item =>
                                    item.operations_highlights!=null?item.operations_highlights.split("\n")[1]:'')
                                }</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 text-center">
                            <div className="icon-box-text mb-0">
                                <img src={require('../../icons/operation-green.svg')} />
                                <p> {reports.map(item =>
                                    item.operations_highlights!=null?item.operations_highlights.split("\n")[2]:'')
                                }</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12"> 
                         {/* <h2 className="text-center" style={{ fontSize: '25px' }}>
                                Summary
                            </h2>  */}
                        {/* <ul className="list-unstyled mt-4 mb-0">
                                {reports.map(item =>
                                    item.operations_highlights.split("\n").map((t, index) => {
                                        return <li key={index} >{t}</li>;
                                    })
                                )}
                            </ul>  */}
                        </div> 
                        <div className="col-12 text-center">
                            <div className="row justify-content-md-center">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#DAF8E8' }}>
                                        <div className="inner-box">
                                            <p>Current Litigations</p>
                                            <h5>{reports.map(item =>
                                                item.operations_current_litigations
                                            )}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#F8FFEB' }}>
                                        <div className="inner-box">
                                            <p>Past Litigations (3yrs)</p>
                                            <h5>{reports.map(item =>
                                                item.operations_past_litigations
                                            )}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#FFF3EB' }}>
                                        <div className="inner-box">
                                            <p>Foreclosures (3yrs)</p>
                                            <h5>{reports.map(item =>
                                                item.operations_foreclosures
                                            )}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#EBFCFF' }}>
                                        <div className="inner-box">
                                            <p>Management</p>
                                            <h5>{reports.map(item =>
                                                item.operations_management
                                            )}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6  col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#FBEBFF' }}>
                                        <div className="inner-box">
                                            <p>Owner occupancy</p>
                                            <h5>{reports.map(item =>
                                                item.operations_owner_occupancy
                                            )}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#FFFBEB' }}>
                                        <div className="inner-box">
                                            <p>Overdue/Defaults</p>
                                            <h5>{reports.map(item =>
                                                item.operations_defaults
                                            )}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#EBF4FF' }}>
                                        <div className="inner-box">
                                            <p>Crime Check <small className='mb-0 mt-0'> (incidents)</small></p> 
                                            <h5>{reports.map(item =>
                                                item.operations_crime_check
                                            )}</h5>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#EBF4EA' }}>
                                        <div className="inner-box">
                                            <p>Sex Offender Check  </p> 
                                            <h5>{reports.map(item =>
                                                item.operations_sex_offender_check
                                            )}</h5>
                                            
                                        </div>
                                    </div>
                                </div>

								<div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#FFEBEB' }}>
                                        <div className="inner-box">
                                            <p>Solar Panel Policy</p>
                                            <h5>{reports.map(item =>
                                                item.operations_solarpanels
                                            )}</h5>
                                        </div>
                                    </div>
                                </div>
								<div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#DAF8E1' }}>
                                        <div className="inner-box">
                                            <p>Wildfire Policy</p>
                                            <h5>{reports.map(item =>
                                                item.operations_wildfire
                                            )}</h5>
                                        </div>
                                    </div>
                                </div>
								<div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#EBFCF1' }}>
                                        <div className="inner-box">
                                            <p>Water Harvesting Policy</p>
                                            <h5>{reports.map(item =>
                                                item.operations_water_harvesting
                                            )}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="box animate-box-bottom" style={{ background: '#EBFDD1' }}>
                                        <div className="inner-box">
                                            <p>Xeriscaping Policy</p>
                                            <h5>{reports.map(item =>
                                                item.operations_xeriscaping
                                            )}</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default OperationsSummary;