/**
 * App Reducers
 */
import { combineReducers } from 'redux';
import reportsReducer from './reportsReducer';

const reducers = combineReducers({
  reportsReducer
});

export default reducers;
