/**
 * App Redux Action Types
 */

// REDUX TYPES

// REPORTS
export const GET_REPORT_DATA = 'GET_REPORT_DATA';
export const GET_REPORT_DATA_SUCCESS = 'GET_REPORT_DATA_SUCCESS';
export const GET_REPORT_DATA_FAILURE = 'GET_REPORT_DATA_FAILURE';
