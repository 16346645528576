import React, { Component } from 'react';
import SemiCircle from '../charts/semiCircle';
/**
 * ACTIONS
 */

class FinancialSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {};

    //BIND EVENTS
  }

  render() {
    const { reports } = this.props.graphData;
    return (
      <section className="section animate-bottom px-0 mb-0">
        <div className="container">
          {/* <h4 className="text-center">{reports.map(item => item.financials_headline)}
          </h4> */}
          <div className="row mb-4">
            <div className="col-12 col-lg-6 text-center">

              {reports.map(item =>
                <SemiCircle key={1} dataSeries={[item.financials_score , 0, 100, 60,0 ]} />
              )}
              <div className="chart-bottom-text">
              <p className="mb-4" style={{ fontWeight: '600', fontSize: '60px' , margin: '-120px' }}>{reports.map(item =>
                      item.financials_score
                    )}</p>
                <p className="mb-0 mt-3" style={{ fontWeight: '600', fontSize: '14px' }}>{reports.map(item =>
                  item.financials_grade
                )}</p>
                <p  className="mt-4" style={{ fontWeight: '600', fontSize: '12px', color: '#667585' }}>The Average Financials <b style={{ color: '#23272C' }}>score is {reports.map(item =>
                  item.financials_score_bm
                )}</b></p>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <h4>
                {/* Summary */}
                {reports.map(item => item.financials_headline)}
              </h4>
              <ul className="list-unstyled mt-4 mb-0">
                {reports.map(item =>
                  item.financials_highlights!=null?item.financials_highlights.split("\n").map((t, index) => {
                    return <li key={index} >{t}</li>;
                  }):''
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FinancialSummary;