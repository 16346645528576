import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class BasicColumnChart extends Component {
  constructor(props) {
    super(props);
	var categories = this.props.dataCategories
	var series = this.props.dataSeries
  var colors = this.props.colors
	console.log(this.props.dataSeries)
	var dataSeries1=[{name:'Your HOA',data:[]},{name:'Average HOA',data:[]}]
	var dataCategories1=[]
	for(var i=0;i<this.props.dataSeries[0].data.length;i++){
		if (!(series[0].data[i]==0 && series[1].data[i]==0)){
			dataCategories1.push(categories[i])	
			dataSeries1[0].data.push(series[0].data[i])
			dataSeries1[1].data.push(series[1].data[i])
		}	
  }
  
	this.state = {
      height: this.props.height,
      series: dataSeries1,
      options: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'bar',
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: this.props.width,
            // endingShape: 'rounded'
            dataLabels: {
              position: 'top',
            },
          },
        },
        
        dataLabels: {
          enabled: this.props.dataLabels,
          offsetX: 0,
          style: {
            fontSize: '12px',
            colors: ['#fff']
          },
          formatter: this.props.formatter,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        colors: colors,
        xaxis: {
          categories: dataCategories1,
          labels: {
            style: {
                fontSize: 12,
                rotate: -45,
                rotateAlways: true,
                // fontWeight: 600
            },
            trim: true,
            showForNullSeries: false
          },
         
        },
        yaxis: {
          title: {
            text: this.props.title
          },
          labels: {
            formatter: this.props.formatter,
          }
        },
        grid: {
          borderColor: '#f9f5f5',
          yaxis: {
            lines: {
              show: true,
              
            }
          },
        
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: this.props.formatter,
          }
        }
      },


    };
  }
  

  render() {
    console.log(this.state)  
    return (
      <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={this.state.height} />
      </div>
    );
  }
}
export default BasicColumnChart;