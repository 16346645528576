import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class DonutChart extends Component {
  constructor(props) {
    super(props);
	console.log(this.props.dataSeries)
    let indexes=[];
	
    let data = this.props.dataSeries.filter((item,index)=>{
      
        indexes.push(index);
        return item;
      
    });
    let dataLab=[];
    indexes.forEach(item=>dataLab.push(this.props.dataCategories[item]))
    console.log(data,dataLab);
    this.state = {
      series: this.props.dataSeries,
      options: {
        chart: {
          width: '100%',
          type: 'donut',
        },
        labels: dataLab,
        name: {
          show: true,
          fontSize: '52px',
      },
      colors:['#3489ff','#ffae30','#ff3130','#bde12b','#5cd15d','#07cccf','#d2691e','#b37fe3','#ed9191','#6495ed','#f77c7c','#9f8fff','#fca4fc','#adff2f'],
        plotOptions: {
          pie: {
            customScale: 0.8,
            donut: {
              size: '50%',
              labels: {
                show: false,
 
                
                // total: {
                //   showAlways: false,
                //   show: true,
                //   label: '',
                //   fontSize: '100px',
                //   fontWeight: 600,
                //   formatter: (w) => {
                  
                //     return '$' + w.globals.seriesTotals.reduce((a, b) => {
                //      let c = a + b
                //       return c 
                //     }).toFixed(0)
                //   }
               // }
              }
            }
          }
        },
        title: {
          text: this.props.title
        },
        dataLabels: {
          style: {
            fontSize: '16px',
        },
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed(0)+'%']
          }
        },
        legend: {
          show: false
        },
        tooltip: {
        
          y: {
            formatter: function (val) {
              return "$"+val+"/month"
            },
            title: {
              formatter: function (seriesName) {
                return seriesName +" : "
              }
            }
          }
        }
      },


    };
  }

  render() {
    return (
      <div id="chart1" >
        <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
      </div>
    );
  }
}
export default DonutChart;