import React, { Component } from 'react';
import SemiCircle from '../charts/semiCircle';
import DonutChart from '../charts/donutChart';
import BasicColumnChart from '../charts/basicColumnChart';

class Expenses extends Component {

  constructor(props) {
    super(props);

    this.state = {};

    //BIND EVENTS
  }

  render() {
    const { reports } = this.props.graphData;
	let actualValue = reports[0].expenses_scaled_size
	let avg = reports[0].expenses_scaled_size_bm
	let color=[]
  console.log(reports)
	if (actualValue > .8*avg & actualValue < 1.2*  avg ){
        color = ['#5cd15d', '#bde12b']
    }
	else if (actualValue >=1.2*avg){
        //higher is worse
        color = ['#ff8383', '#ffbfbf']
    }else if (actualValue < .8*avg)
    {
		color = ['#5cd15d', '#bde12b']
    }else{
      color = ['#5cd15d', '#bde12b']
    }
    return (
      <section className="section animate-bottom px-0 mb-0">
        <div className="container">
          {/* <h4 className="text-center"> 
            {reports.map(item => item.expenses_headline)}
          </h4> */}
          <div className="row mb-4">
            <div className="col-12 col-lg-6 text-center">
              {/* <h3>HOA expenses adj by unit size
            </h3> */}
              {reports.map(item =>
                <SemiCircle key={1} dataSeries={[item.expenses_scaled_size, item.expenses_scaled_size_bm_min, item.expenses_scaled_size_bm_max, item.expenses_scaled_size_bm, 1]} />
              )}
              <div className="chart-bottom-text">
                 <p className="mb-5" style={{ fontWeight: '600', fontSize: '60px' , margin: '-120px' }}>${reports.map(item =>
                      item.expenses_scaled_size
                    )}</p>
                  
                {reports.map(item =>
                  item.expenses_scaled_size_comment!=null?item.expenses_scaled_size_comment.split("\n").map((t, index) => {
                    return <p className="mt-4" key={index} >{t}</p>;
                  }):''
                )}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              {/* <h2 className="text-center">
                Good to know
        </h2> */}
              <h4>{reports.map(item => item.expenses_headline)}</h4>
              <ul className="list-unstyled mt-4 mb-0">
                {reports.map(item =>
                  item.expenses_highlights!=null?item.expenses_highlights.split("\n").map((t, index) => {
                    return <li key={index} >{t}</li>;
                  }):''
                )}
              </ul>
            </div>
          </div>

          <div className="row mb-4 mt-5 circle-section">
            <div className="col-12 col-md-12 text-center">
              <h3>Monthly HOA Expenses (compared to similar homes)    </h3>

            {reports.map(item =>
                <BasicColumnChart key={0} height={500} dataLabels={false} width={'60%' } colors={color}
                  formatter={function(val) {
                      return val !== 0 ? "$"+ val.toLocaleString() : ''                       
                  }}

                 

                 dataSeries={[{ name: 'Your HOA', data: [item.expenses_insurance_scaled,
                  item.expenses_wages_scaled ?? 0,
                  item.expenses_management_scaled ?? 0,
                  item.expenses_electric_scaled ?? 0,
                  item.expenses_water_scaled ?? 0,
                  item.expenses_other_scaled ?? 0,
                  item.expenses_pool_scaled ?? 0,
                  item.expenses_landscape_scaled ?? 0,
                  item.expenses_snow_scaled ?? 0,
                  item.expenses_janitorial_scaled ??0 ,
                  item.expenses_garbage_scaled ??0,
                  item.expenses_security_scaled ?? 0,
                  item.expenses_maint_scaled ??0,
                  item.expenses_property_tax_scaled ??0,
                  item.expenses_cable_scaled ??0,
                  item.expenses_social_scaled ??0,
                  item.expenses_pest_control_scaled ??0,
                  item.expenses_club_house_scaled

                  ] },
                  { name: 'Average HOA', data: [item.expenses_insurance_scaled_bm,
                    item.expenses_wages_scaled_bm,
                    item.expenses_management_scaled_bm,
                    item.expenses_electric_scaled_bm,
                    item.expenses_water_scaled_bm,
                    item.expenses_other_scaled_bm,
                    item.expenses_pool_scaled_bm,
                    item.expenses_landscape_scaled_bm,
                    item.expenses_snow_scaled_bm,
                    item.expenses_janitorial_scaled_bm,
                    item.expenses_garbage_scaled_bm,
                    item.expenses_security_scaled_bm,
                    item.expenses_maint_scaled_bm,
                    item.expenses_property_tax_scaled_bm,
                    item.expenses_cable_scaled_bm,
                    item.expenses_social_scaled_bm,
                    item.expenses_pest_control_scaled_bm,
                    item.expenses_club_house_scaled_bm
                    ] } ] } dataCategories={["Insurance", "Wages", "Management", "Electric", "Water", "Other", "Pool", "Landscape", "Snow", "Janitorial", "Garbage", "Security", "Repairs", "Property Tax", "Cable/Internet", "Social","Pest Control", "Club House" ]} 
                />
              )}
              
  
            </div>
          </div>

          <div className="row mb-4 mt-5 circle-section">
            <div className="col-12 col-md-6 text-center">
              <h3>Your HOA
            </h3>
              {reports.map(item =>
                <DonutChart key={1}
                  dataSeries={[item.expenses_insurance_scaled,
				  item.expenses_wages_scaled,
                  item.expenses_management_scaled,
                  item.expenses_electric_scaled,
                  item.expenses_water_scaled,
                  item.expenses_other_scaled ?? 0,
                  item.expenses_pool_scaled,
                  item.expenses_landscape_scaled,
                  item.expenses_snow_scaled ?? 0,
                  item.expenses_janitorial_scaled,
                  item.expenses_garbage_scaled,
                  item.expenses_security_scaled,
                  item.expenses_maint_scaled,
                  item.expenses_property_tax_scaled,
                  item.expenses_cable_scaled,
                  item.expenses_social_scaled,
                  item.expenses_pest_control_scaled,
                  item.expenses_club_house_scaled
                  ]} dataCategories={["Insurance", "Wages", "Management", "Electric", "Water", "Other", "Pool", "Landscape", "Snow", "Janitorial", "Garbage", "Security", "Repairs", "Property Tax", "Cable/Internet", "Social","Pest Control", "Club House"]} />
              )}
              <div className="chart-bottom-text">
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <h3>Average HOA
            </h3>
              {reports.map(item =>
                <DonutChart key={1} dataSeries={[item.expenses_insurance_scaled_bm,
                item.expenses_wages_scaled_bm,
                item.expenses_management_scaled_bm,
                item.expenses_electric_scaled_bm,
                item.expenses_water_scaled_bm,
                item.expenses_other_scaled_bm,
                item.expenses_pool_scaled_bm,
                item.expenses_landscape_scaled_bm,
                item.expenses_snow_scaled_bm,
                item.expenses_janitorial_scaled_bm,
                item.expenses_garbage_scaled_bm,
                item.expenses_security_scaled_bm,
                item.expenses_maint_scaled_bm,
                item.expenses_property_tax_scaled_bm,
                item.expenses_cable_scaled_bm,
                item.expenses_social_scaled_bm,
                item.expenses_pest_control_scaled_bm,
                item.expenses_club_house_scaled_bm
                ]} dataCategories={["Insurance", "Wages", "Management", "Electric", "Water", "Other", "Pool", "Landscape", "Snow", "Janitorial", "Garbage", "Security", "Repairs", "Property Tax", "Cable/Internet", "Social","Pest Control", "Club House"]} />
              )}
            </div>
          </div>
        </div>

      </section>
    );
  }
}

export default Expenses;