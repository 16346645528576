const axios = require('axios');

const { REACT_APP_API_ENDPOINT } = process.env;

export function callPostAPI(url, payload, authToken) {
  return axios.post(url, payload)
      .then(response => {
      return response;
    })
      .catch((error) => {
        return { error: error.response ? error.response : 'Something went wrong. Please try again later.' };
    });
}

export function callGetAPI(url, payload, authToken) {
  const data = {
    headers: { 'Authorization': 'Bearer ' + authToken }
  };
  return axios.get(url, data)
    .then(response => {
      return response;
    })
    .catch((error) => {
      return { error: error.message ? error.message : 'Something went wrong. Please try again later.' };
    });
}


export function callPutAPI(endpoint, payload, authToken) {
  const data = {
    body: payload,
    headers: { 'Authorization': authToken }
  };
  return axios.put(REACT_APP_API_ENDPOINT, endpoint, data)

    .then(response => {
      return response;
    })
    .catch((error) => {
      return { error: error.message ? error.message : 'Something went wrong. Please try again later.' };
    });
}

// EXPORT MODULES
export * from './auth';
