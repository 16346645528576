import { callGetAPI } from './index'
import Config from '../config';

export const getReports = async (propertyUrl) => {
	var blurPropertyUrl = propertyUrl.split('/')[1]
	if(blurPropertyUrl=='details'){
		propertyUrl = '/'+propertyUrl.split('/')[2]
	}
    var url = Config._API + propertyUrl;//`/22-Dr-Martin-Luther-King-Jr-Dr-Norwalk-CT-06854`;
    return await callGetAPI(url, undefined, Config._TOKEN);
};
