import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class LineChart extends Component {
  constructor(props) {
    super(props)
    this.state = {

      series: this.props.dataSeries,
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
		colors:['#7aef98', '#dede02', '#ff1313'],
		dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return "$"+val.toLocaleString(undefined, {maximumFractionDigits:0})
        }
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: this.props.title,
          align: 'left'
        },
        grid: {
          borderColor: '#f9f5f5',
          yaxis: {
            lines: {
              show: true,
              
            }
          },
        },
        xaxis: {
          categories: this.props.dataCategories,
        },
         
      },


    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
      </div>
    );
  }
}
export default LineChart;