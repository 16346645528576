import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class GroupedBarChart extends Component {
  constructor(props) {
    super(props);
	let color = []
	if (this.props.colors)
	{
		color = this.props.colors
	}else{
		color = ['#5cd15d', '#bde12b']	
	}
    this.state = {

      series: this.props.dataSeries,
      options: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'bar',
          height: 430
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '40%',
            dataLabels: {
              position: 'top',
            },
          },
          
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: '12px',
            colors: ['#fff']
          },
          formatter: function(val) {
            return   val + "%"
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        colors: color,
        xaxis: {
          categories: this.props.dataCategories
        },
        yaxis:{
          labels: {
            style: {
                fontSize: 16,
                cssClass:"break-lable-y"
                // fontWeight: 600
            }
          }
        },
        grid: {
          borderColor: '#f9f5f5',
          yaxis: {
            lines: {
              show: true,
              
            }
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val+"%";
            }
          }
        }

      },
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
      </div>
    );
  }
}
export default GroupedBarChart;