import React, { Component } from 'react';

class NoDataAlert extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container">
             <div className="position-relative">
                 <div className="alert-box-page">
                <h3 className="align-self-center mb-0">{this.props.errorMessage}</h3>
            </div>
            </div>
      </div>
    );
  }
}

export default NoDataAlert;