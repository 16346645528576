import React, { Component } from 'react';
import SemiCircle from '../charts/semiCircle';

class FinancingSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        //BIND EVENTS
    }

    render() {
        const { reports } = this.props.graphData;
        return (
            <section className="section animate-bottom mb-0 my-0 px-0 pb-0">
                <div className="container">
                <h4 className="text-center">
                         {/* {reports.map(item => item.operations_headline)}  */}
                    Financing Summary
                     </h4>
                    {/* <h4 className="text-center">{reports.map(item => item.financing_headline)}
                    </h4> */}
                     <div className="row mb-5">
                        <div className="col-12 col-sm-4 text-center">
                            <div className="icon-box-text fincials-icon">
                                <img src={require('../../icons/finacials-green.svg')} />
                                <p> {reports.map(item =>
                                    item.financing_highlights!=null?item.financing_highlights.split("\n")[0]:'')
                                }</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 text-center">
                            <div className="icon-box-text bylwas-icon">
                                <img src={require('../../icons/bylaws-green.svg')} />
                                <p> {reports.map(item =>
                                    item.financing_highlights!=null?item.financing_highlights.split("\n")[1]:'')
                                }</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 text-center">
                            <div className="icon-box-text mb-0 operation-icon">
                                <img src={require('../../icons/operation-green.svg')} />
                                <p> {reports.map(item =>
                                    item.financing_highlights!=null?item.financing_highlights.split("\n")[2]:'')
                                }</p>
                            </div>
                        </div>
                    </div> 
                    <div className="row">
                        {/* <div className="col-12 col-lg-6 text-center">

                            {reports.map(item =>
                                <SemiCircle key={1} dataSeries={[item.financing_score]} />
                            )}
                            <div className="chart-bottom-text">
                                <p className="mb-0" style={{ fontWeight: '600', fontSize: '20px' }}>{reports.map(item =>
                                    item.financing_grade
                                )}</p>
                                <p>The average Financials score is <b>{reports.map(item =>
                                    item.financing_score_bm
                                )}</b></p>
                            </div>
                        </div> */}
                        {/* <div className="col-12 col-lg-6 mt-4 mt-md-0">
                    
                            <h4 className="">{reports.map(item => item.financing_headline)}
                            </h4>
                            <ul className="list-unstyled mt-4">
                                {reports.map(item =>
                                    item.financing_highlights.split("\n").map((t, index) => {
                                        return <li key={index} >{t}</li>;
                                    })
                                )}
                            </ul>
                        </div> */}
                    </div>
                </div>
                <div className="row mb-4 mt-4">
                    <div className="col-12 text-center">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="box animate-box-bottom" style={{ background: '#DAF8E8' }}>
                                    <div className="inner-box">
                                        <p>FHA Financing</p>
                                        <h5>{reports.map(item =>
                                            item.financing_fha
                                        )}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="box animate-box-bottom" style={{ background: '#F8FFEB' }}>
                                    <div className="inner-box">
                                        <p>VA Financing</p>
                                        <h5>{reports.map(item =>
                                            item.financing_va
                                        )}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="box animate-box-bottom" style={{ background: '#FFFBEB' }}>
                                    <div className="inner-box">
                                        <p>Units overdue 60 days</p>
                                        <h5>{reports.map(item =>
                                            item.financing_units_overdue
                                        )}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="box animate-box-bottom" style={{ background: '#FFF3EB' }}>
                                    <div className="inner-box">
                                        <p>Owner occupancy</p>
                                        <h5>{reports.map(item =>
                                            item.financing_owner_occupancy
                                        )}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="box mb-lg-0 mb-md-0 mb-sm-0 animate-box-bottom" style={{ background: '#EBFCFF' }}>
                                    <div className="inner-box">
                                        <p>Flood Zone</p>
                                        <h5>{reports.map(item =>
                                            item.financing_flood_zone
                                        )}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="box mb-lg-0 mb-md-0 animate-box-bottom" style={{ background: '#EBF4FF' }}>
                                    <div className="inner-box">
                                        <p>Reserves % of Budget</p>
                                        <h5>{reports.map(item =>
                                            item.financing_reserves_pct_budget
                                        )}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="box mb-lg-0 mb-md-0 mb-sm-0 animate-box-bottom" style={{ background: '#FBEBFF' }}>
                                    <div className="inner-box">
                                        <p>Insurance coverage</p>
                                        <h5>{reports.map(item =>
                                            item.financing_insurance_cvg
                                        )}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="box mb-0 animate-box-bottom" style={{ background: '#FFEBEB' }}>
                                    <div className="inner-box">
                                        <p>Year built</p>
                                        <h5>{reports.map(item =>
                                            item.financing_year_built
                                        )}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FinancingSummary;