import React, { Component } from 'react';

class MustKnow extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        //BIND EVENTS
    }

    render() {
        const { reports } = this.props.graphData;

        return (
            <section className="section animate-bottom my-0 px-0 pb-0">
                <div className="container">
                    <h4 className="mb-4">Must know bylaws
      </h4>
                    <div className="row mb-4">
                        <div className="col-12">
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th className="w-20">Category</th>
                                        <th className="w-70">Bylaw</th>
                                        <th className="w-10">Page numbers</th>

                                    </tr>
                                </thead>
                                {reports.map(item =>
                                    <tbody dangerouslySetInnerHTML={{ __html: item.bylaws_must_know?item.bylaws_must_know.replace('<table>', '').replace('</table>', ''):'' }} />
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default MustKnow;