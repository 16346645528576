import React, { Component } from 'react';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
 
          
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-3">
                  <h3>Quick Links</h3>
                  <ul className="list-unstyled mb-0 foot-nav-links">
                    <li>
                      <a href="https://inspecthoa.com/about-us/">About Us</a>
                    </li>
                    <li>
                      <a href="https://inspecthoa.com/hoa-inspection/">Order </a>
                    </li>
                    <li>
                      <a href="https://inspecthoa.com/how-it-works/">How it works</a>
                    </li>
                    <li>
                      <a href="https://inspecthoa.com/faq/">FAQ</a>
                    </li>
                    <li>
                      <a href="https://inspecthoa.com/tos/">Terms of Service</a>
                    </li>
                    
                  </ul>
                </div>

                <div className="col-12 col-sm-12 col-md-6 mb-4 mb-md-0">
                  <h3>Inspect HOA</h3>
                  <p>We deliver the most accurate estimate of a Homeowner Association’s financial and operational health so potential buyers can make an informed investment decision.</p>
                </div>
                
                {/* <div className="col-12 col-sm-6 col-md-3 my-4 my-sm-0">
                  <h3>For Agents</h3>
                  <p>If you are an agent, please
                  </p>
                  <button className="btn click-btn"   >   <a href="https://inspecthoa.com/agents/"/>click here</button>
                </div> */}
                <div className="col-12 col-sm-6 col-md-3">
                  <h3>Follow us</h3>
                  <ul className="list-unstyled mb-0 d-flex social-icons-list">
                    <li>
                      <a href="https://facebook.com/inspecthoa" className="fb"><i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/hoainspect" className="twiter"><i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            
          <div className="copy-right-section">
            <div className="container">
              <p>Copyright © 2021 Inspect HOA | All Rights Reserved</p>
            </div>
          </div>
          
          </footer>

         
    );
  }
}

export default (Footer);
