/**
 * TYPES
 */
import {
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAILURE
} from './types';

/**
 * API ACTIONS
 */

export const getReportData = (url) => ({
  type: GET_REPORT_DATA,
  payload: url
})

export const getReportDataSuccess = (success) => ({
  type: GET_REPORT_DATA_SUCCESS,
  payload: { success }
})

export const getReportDataFailure = (failure) => ({
  type: GET_REPORT_DATA_FAILURE,
  payload: { failure }
})
