import React, { Component } from 'react';

class NiceToKnow extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        //BIND EVENTS
    }

    render() {
        const { reports } = this.props.graphData;        
        return (
            <section className="section animate-bottom px-0 my-0 pb-0">
                <div className="container">
                    <h4 className="mb-4">Good to know bylaws
      </h4>
                    <div className="row mb-4">
                        <div className="col-12">
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th className="w-20">Category</th>
                                        <th className="w-70">Bylaw</th>
                                        <th className="w-10">PageNumbers</th>
                                        
                                    </tr>
                                </thead>
                                {reports.map(item =>
                                    <tbody dangerouslySetInnerHTML={{ __html: item.bylaws_g2k!=null?item.bylaws_g2k.replace('<table>', '').replace('</table>', ''):'' }} />
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default NiceToKnow;