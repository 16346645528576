import React, { Component } from 'react';
import SemiCircle from '../charts/semiCircle';

class BylawsSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {};

    //BIND EVENTS
  }


  render() {
    const { reports } = this.props.graphData;
    return (
      <section className="section animate-bottom mb-0 px-0">
        <div className="container">
          {/* <h4 className="text-center">{reports.map(item => item.bylaws_headline)}
        </h4> */}
          <div className="row mb-4">
            <div className="col-12 col-lg-6 text-center">
              <h3>Bylaws Score
              </h3>
              {reports.map(item =>
                <SemiCircle key={1} dataSeries={[item.bylaws_score , 0, 100, 60,0 ]} />
              )}
              <div className="chart-bottom-text">

              <p className="mb-4" style={{ fontWeight: '600', fontSize: '60px' , margin: '-120px' }}>{reports.map(item =>
                      item.bylaws_score
                    )}</p>
                <p className="mb-0 mt-3" style={{ fontWeight: '600', fontSize: '14px' }}>{reports.map(item =>
                  item.bylaws_grade
                )}</p>

              <p className="mt-4" style={{ fontWeight: '600', fontSize: '12px', color: '#667585' }}>The Average Bylaw <b style={{ color: '#23272C' }}>score is {reports.map(item =>
                      item.bylaws_score_bm
                    )}</b></p>

               
              </div>
            </div>
            <div className="col-12 col-lg-6">
              {/* <h2 className="text-center">
                Summary
          </h2> */}
              <h4 className="">{reports.map(item => item.bylaws_headline)}
              </h4>
              <ul className="list-unstyled mt-4 mb-0">
                {reports.map(item =>
                  item.bylaws_highlights!=null?item.bylaws_highlights.split("\n").map((t, index) => {
                    return <li key={index} >{t}</li>;
                  }):''
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BylawsSummary;