import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as API from '../api';
import {
    GET_REPORT_DATA
} from '../actions/types';

import {
    getReportDataSuccess,
    getReportDataFailure
} from '../actions/reportsActions';


function* getReportsData(url) {
    const response = yield call(API.getReports , url.payload);
    if (response) {
        if (response.status === 200) {
            yield put(getReportDataSuccess(response.data));
        } else {
            yield put(getReportDataFailure(response.error ? response.error : "Something went wrong. Please try again later."));
        }
    }
}

export function* getReports() {
    yield takeEvery(GET_REPORT_DATA, getReportsData);
}

export default function* rootSaga() {
    yield all([
        fork(getReports),
    ]);
}
