/**
 * Auth User Reducers
 */
import {
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAILURE
} from '../actions/types';

// import constants from '../actions/constants';
// import toastr from 'toastr'
// import 'toastr/build/toastr.min.css'
// toastr.options.timeOut  = 1500;

/**
 * initial auth user
 */

const INIT_STATE = {
  loading: false,
  reports: []
};


export default (state = INIT_STATE, action) => {
  // toastr.clear();
  switch (action.type) {
    case GET_REPORT_DATA:
      return { ...state, loading: true }

    case GET_REPORT_DATA_SUCCESS:
      return { ...state, loading: false, reports: action.payload.success }

    case GET_REPORT_DATA_FAILURE:
      console.log(action.payload)
      return { ...state, loading: false }

    default:
      return state;
  }
}
