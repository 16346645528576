import React from "react"
//import ContentLoader from "react-content-loader"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

const ContentLoader = (props) => (
      //   <ContentLoader 
      //     speed={2}
      //     width={window.innerWidth}
      //     height={window.innerHeight}
      //     viewBox="0 0 400 150"
      //     backgroundColor="#917e7e"
      //     foregroundColor="#ecebeb"
      //     {...props}
      //   >
      //     <circle cx="10" cy="20" r="8" /> 
      //     <rect x="25" y="15" rx="5" ry="5" width="220" height="10" /> 
      //     <circle cx="10" cy="50" r="8" /> 
      //     <rect x="25" y="45" rx="5" ry="5" width="220" height="10" /> 
      //     <circle cx="10" cy="80" r="8" /> 
      //     <rect x="25" y="75" rx="5" ry="5" width="220" height="10" /> 
      //     <circle cx="10" cy="110" r="8" /> 
      //     <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
      //   </ContentLoader>
      
      <div className="loader-centered">
            <Loader
                  type="Circles"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  timeout={300000000} //3 secs

            />
      </div>
)

export default ContentLoader