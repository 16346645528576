import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getReportData
} from '../actions/reportsActions';
import Button from '@material-ui/core/Button';
import SemiCircle from './charts/semiCircle';
import FinancialSummary from '../components/financials';
import Fees from '../components/financials/fees';
import Expenses from '../components/financials/expenses';
import Reserves from '../components/financials/reserves';
import BylawsSummary from './byLawsOperations';
import MustKnow from './byLawsOperations/mustKnow';
import NiceToKnow from './byLawsOperations/niceToKnow';
import FinancingSummary from '../components/financing';
import OperationsSummary from '../components/operations';
import NoDataAlert from '../components/models/noDataAlert';
import ContentLoader from './loader';

import { VscFilePdf } from 'react-icons/vsc';

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      viewForm: false,
      detailForm: null,
      mainSelection: null,
      subMenuSelection: null,
      width: window.innerWidth,
	  isLimited: 'block',
    };
    this.onMainChangeHandle = this.onMainChangeHandle.bind(this);
    this.onSubMenuChangeHandle = this.onSubMenuChangeHandle.bind(this);
  }

  //Main menu click handler
  onMainChangeHandle(val) {
    switch (val) {
      case "financials":
        this.setState({ mainSelection: 1 })
        this.onSubMenuChangeHandle("financialsSummary")
        break;
      case "BylawsOperations":
        this.setState({ mainSelection: 2 })
        this.onSubMenuChangeHandle("byLawsMustKnow")
        break;
      case "operations":
        this.setState({ mainSelection: 3 })
        this.onSubMenuChangeHandle("operationsSummary")
        break;
      case "Financing":
        this.setState({ mainSelection: 4 })
        this.onSubMenuChangeHandle("financingsSummary")
        break;
      default:
        this.setState({ mainSelection: null })
    }

  }
  //Sub menu click handler
  onSubMenuChangeHandle(val) {
    switch (val) {
      case "financialsSummary":
        this.setState({ detailForm: <FinancialSummary graphData={this.props.reportsReducer} />, subMenuSelection: 1 })
        break;
      case "financialsFees":
        this.setState({ detailForm: <Fees graphData={this.props.reportsReducer} />, subMenuSelection: 2 })
        break;
      case "financialsExpenses":
        this.setState({ detailForm: <Expenses graphData={this.props.reportsReducer} />, subMenuSelection: 3 })
        break;
      case "financialsReserves":
        this.setState({ detailForm: <Reserves graphData={this.props.reportsReducer} />, subMenuSelection: 4 })
        break;
      // case "byLawsSummary":
      //   this.setState({ detailForm: <BylawsSummary graphData={this.props.reportsReducer} />, subMenuSelection: 1 })
      //   break;
      case "byLawsMustKnow":
        this.setState({ detailForm: <MustKnow graphData={this.props.reportsReducer} />, subMenuSelection: 2 })
        break;
      case "byLawsNiceToKnow":
        this.setState({ detailForm: <NiceToKnow graphData={this.props.reportsReducer} />, subMenuSelection: 3 })
        break;
      case "operationsSummary":
        this.setState({ detailForm: <OperationsSummary graphData={this.props.reportsReducer} />, subMenuSelection: 1 })
        break;
      case "financingsSummary":
        this.setState({ detailForm: <FinancingSummary graphData={this.props.reportsReducer} />, subMenuSelection: 1 })
        break;
      default:
        this.setState({ detailForm: null })
    }
    this.setState({ viewForm: true });
  }
  componentDidMount() {
    this.props.getReportData(this.props.propertName);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { reports, loading } = this.props.reportsReducer;
	
    if (reports.length === 0 && !loading) {
      return (<NoDataAlert errorMessage={'No Data Found!'} />);
    }

    if (loading) {
      return (<ContentLoader />);
    }
    const { width } = this.state;
    const isMobile = width <= 767;
    let bylaws_g2k_na = reports.filter(item => {
      if (item.bylaws_g2k === 'NA') {
        return 'NA';
      }
    }
    );

    
    return (
      <>
        <section className="section-one">
          
          <div className="container">
            
            <div className="bg-white white-section mt-4 ">
            
                    {reports.map(item => (item.client_name ) ? 
                    <p className='text-right  text-muted small mb-0 mt-0'>
                     {"Prepared for " + item.client_name}
                     </p> :  ''
                    )}
               
              <div className="row pb-4 highlights_screensht">
                <div className="col-12 col-lg-6 text-center">
               
                  {reports.map(item =>
                    <SemiCircle key={item.summary_score} dataSeries={[item.summary_score, 0, 100, 60,0]} />
                  )}
                
                  <div className="chart-bottom-text  text-center">
                    
                  <p className="mb-4" style={{ fontWeight: '600', fontSize: '60px' , margin: '-120px' }}>{reports.map(item =>
                      item.summary_score == 0 ? '': item.summary_score
                    )}</p>
                    <p className="mb-0 mt-3" style={{ fontWeight: '600', fontSize: '14px'  }}>{reports.map(item =>
                      item.summary_grade  
                    )}</p>
                    {/* <p className="mb-0">Market Value Adjustment: <span style={{ color: 'rgb(193 2 1)' }}>{reports.map(item =>
                    item.summary_market_value_adjustment
                  )} </span></p> */}
                    <p className="mt-4" style={{ fontWeight: '600', fontSize: '12px', color: '#667585' }}>The Average HOA <b style={{ color: '#23272C' }}>score is {reports.map(item =>
                      item.summary_score_bm
                    )}</b></p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 align-self-center">
                  
                  <div className="row">
                <div className="col-10" >
                  <h2>
                    Highlights
                 </h2>
                </div>
                 <div className="col-2 text-right">
                  <Button style={{color: 'grey'}}  className="w-auto mb-0" onClick={ () => 
                    window.open(
                    reports.map(item =>
                      
                      item.pdf_link
                    ) ,"_blank")
                  } >
                  <VscFilePdf size={25} ></VscFilePdf>
                
                  </Button>
                </div>
                </div>

                  <ul className="list-unstyled mt-4">
                    {reports.map(item =>
                      item.summary_highlights!=null?item.summary_highlights.split("\n").map((t, index) => {
                        return <li key={index}>{t}</li>;
                      }):''
                    )}
                  </ul>
                  <h6>
                    <b>{reports.map(item => item.prop_address)}</b>
                    {/* <small> - {reports.map(item => item.summary_hoa_name)}</small> */}
                  </h6>
 
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="container">
          <div className="row py-3"> */}

        {/* <div className="col-9 text-left">
          <h4><b>{reports.map(item =>item.prop_address)}</b>
          </h4>
          </div> */}

        {/* 
          </div>
        </div> */}
        <section className="section-one">
          <div className="container">
            <div className="row mb-4">
              <div className="col-12 text-center">
                <h6 className="Hoa-scrore-txt">Explore each section to better understand your HOA</h6>
              </div>
            </div>
            {/* <div className="about-hoaScore text-center pb-4">
              To better understand your HOA score, check each of these factors
          </div> */}

            {/* //Start Mobile View */}
            {isMobile && <div>
              <div className="row mb-4 main-row">
                <div className="col-12">
                {reports[0].limited_report!='1' &&   <Button variant="outlined" color="primary" className={this.state.mainSelection === 1 ? "menu-active" : ""} onClick={() => this.onMainChangeHandle("financials")}>
                    <span className="fincials-icon">
                      {this.state.mainSelection === 1 && <img src={require('../icons/finacials-white.svg')} />}
                      {this.state.mainSelection !== 1 && <img src={require('../icons/finacials-green.svg')} />}
                    </span>
                    Financials
          </Button>}
				
                  {this.state.mainSelection === 1 &&
                    <div className="bg-white white-section">
                      <div className="row mb-3 mx-2 sub-row">
                        <div className="col-12">
                          <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 1 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("financialsSummary")}>
                            <span>
                              {this.state.subMenuSelection === 1 && <img src={require('../icons/Summary-white.svg')} />}
                              {this.state.subMenuSelection !== 1 && <img src={require('../icons/Summary.svg')} />}
                            </span>
                        Summary
          </Button>
                        </div>
                        <div className="col-12">
                          <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 2 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("financialsFees")}>
                            <span>
                              {this.state.subMenuSelection === 2 && <img src={require('../icons/Fee-white.svg')} />}
                              {this.state.subMenuSelection !== 2 && <img src={require('../icons/Fee.svg')} />}
                            </span>
                        Fees
          </Button>
                        </div>
                        <div className="col-12">
                          <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 3 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("financialsExpenses")}>
                            <span>
                              {this.state.subMenuSelection === 3 && <img src={require('../icons/Expenses-white.svg')} />}
                              {this.state.subMenuSelection !== 3 && <img src={require('../icons/Expenses.svg')} />}
                            </span>
                        Expenses
          </Button>
                        </div>
                        <div className="col-12">
                          <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 4 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("financialsReserves")}>
                            <span>
                              {this.state.subMenuSelection === 4 && <img src={require('../icons/Reserves-white.svg')} />}
                              {this.state.subMenuSelection !== 4 && <img src={require('../icons/Reserves.svg')} />}
                            </span>
                        Reserves
          </Button>
                        </div>
                      </div>
                      <div>
                        {(this.state.viewForm) ? this.state.detailForm : ''}
                      </div>
                    </div>
                  }
                </div>
                <div className="col-12">
                  <Button variant="outlined" color="primary" className={this.state.mainSelection === 2 ? "menu-active" : ""} onClick={() => this.onMainChangeHandle("BylawsOperations")}>
                    <span className="bylwas-icon">
                      {this.state.mainSelection === 2 && <img src={require('../icons/bylaws-white.svg')} />}
                      {this.state.mainSelection !== 2 && <img src={require('../icons/bylaws-green.svg')} />}
                    </span>
                    Bylaws
          </Button>
                  {this.state.mainSelection === 2 &&
                    <div className="bg-white white-section">
                      <div className="row mx-3 sub-row">
                        {/* <div className="col-12">
                          <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 1 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("byLawsSummary")}>
                            <span>
                              {this.state.subMenuSelection === 1 && <img src={require('../icons/Summary-white.svg')} />}
                              {this.state.subMenuSelection !== 1 && <img src={require('../icons/Summary.svg')} />}
                            </span>
                        Summary
          </Button>
                        </div> */}     
                                   
                        <div className="col-12">
                          <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 2 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("byLawsMustKnow")}>
                            Must know
          </Button>
                        </div>
                        <div className="col-12">
                          <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 3 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("byLawsNiceToKnow")}>
                            Good To know
          </Button>
                        </div>
                      </div>
                      <div>
                        {(this.state.viewForm) ? this.state.detailForm : ''}
                      </div>
                    </div>
                  }
                </div>
                <div className="col-12">
                  <Button variant="outlined" color="primary" className={this.state.mainSelection === 3 ? "menu-active" : ""} onClick={() => this.onMainChangeHandle("operations")}>
                    <span className="operation-icon">
                      {this.state.mainSelection === 3 && <img src={require('../icons/operation-white.svg')} />}
                      {this.state.mainSelection !== 3 && <img src={require('../icons/operation-green.svg')} />}
                    </span>
                    Operations
          </Button>
                  {this.state.mainSelection === 3 &&
                    <div className="bg-white white-section">
                      {/* <div className="row mb-3 mx-5 sub-row mt-5 pt-4">
                        <div className="col-12">
                          <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 1 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("operationsSummary")}>
                            <span>
                              {this.state.subMenuSelection === 1 && <img src={require('../icons/Summary-white.svg')} />}
                              {this.state.subMenuSelection !== 1 && <img src={require('../icons/Summary.svg')} />}
                            </span>
                        Summary
          </Button>
                        </div>
                      </div> */}
                      <div>
                        {(this.state.viewForm) ? this.state.detailForm : ''}
                      </div>
                    </div>
                  }
                </div>
                <div className="col-12">
                  <Button variant="outlined" color="primary" className={this.state.mainSelection === 4 ? "menu-active" : ""} onClick={() => this.onMainChangeHandle("Financing")}>
                    <span className="finacing-icons">
                      {this.state.mainSelection === 4 && <img src={require('../icons/finacing-white.svg')} />}
                      {this.state.mainSelection !== 4 && <img src={require('../icons/finacing-green.svg')} />}
                    </span>
                    Financing
          </Button>
                  {this.state.mainSelection === 4 &&
                    <div className="bg-white white-section">
                      {/* <div className="row mx-3 sub-row">
                        <div className="col-12">
                          <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 1 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("financingsSummary")}>
                            <span>
                              {this.state.subMenuSelection === 1 && <img src={require('../icons/Summary-white.svg')} />}
                              {this.state.subMenuSelection !== 1 && <img src={require('../icons/Summary.svg')} />}
                            </span>
                        Summary
          </Button>
                        </div>
                      </div> */}
                      <div>
                        {(this.state.viewForm) ? this.state.detailForm : ''}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            }
            {/* //End Mobile View */}



            {/* //Start Desktop  View */}
            {isMobile === false && <div>
              <div className="row mb-4 main-row justify-content-md-center">
                {reports[0].limited_report!='1' && <div className="col-3 financials_click">
                  <Button variant="outlined" color="primary" className={this.state.mainSelection === 1 ? "menu-active" : ""} onClick={() => this.onMainChangeHandle("financials")}>
                    <span className="fincials-icon">
                      {this.state.mainSelection === 1 && <img src={require('../icons/finacials-white.svg')}/>}
                      {this.state.mainSelection !== 1 && <img src={require('../icons/finacials-green.svg')} />}
                    </span>
                    Financials
				</Button>
                </div>}
                <div className="col-3 bylaws_click">
                  <Button variant="outlined" color="primary" className={this.state.mainSelection === 2 ? "menu-active" : ""} onClick={() => this.onMainChangeHandle("BylawsOperations")}>
                    <span className="bylwas-icon">
                      {this.state.mainSelection === 2 && <img src={require('../icons/bylaws-white.svg')} />}
                      {this.state.mainSelection !== 2 && <img src={require('../icons/bylaws-green.svg')} />}
                    </span>
                    Bylaws
          </Button>
                </div>
                <div className="col-3 operations_click">
                  <Button variant="outlined" color="primary" className={this.state.mainSelection === 3 ? "menu-active" : ""} onClick={() => this.onMainChangeHandle("operations")}>
                    <span className="operation-icon">
                      {this.state.mainSelection === 3 && <img src={require('../icons/operation-white.svg')} />}
                      {this.state.mainSelection !== 3 && <img src={require('../icons/operation-green.svg')} />}
                    </span>
                    Operations
          </Button>
                </div>
                <div className="col-3 financing_click">
                  <Button variant="outlined" color="primary" className={this.state.mainSelection === 4 ? "menu-active" : ""} onClick={() => this.onMainChangeHandle("Financing")}>
                    <span className="finacing-icons">
                      {this.state.mainSelection === 4 && <img src={require('../icons/finacing-white.svg')} />}
                      {this.state.mainSelection !== 4 && <img src={require('../icons/finacing-green.svg')} />}
                    </span>
                    Financing
          </Button>
                </div>
              </div>
              <div className={this.state.mainSelection > 0 ? "bg-white white-section screensht" : "screensht"}>
                {this.state.mainSelection > 0 &&

                  <div>
                    {this.state.mainSelection === 1 && <div className="row sub-row">
                      <div className="col-3 fs_click">
                        <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 1 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("financialsSummary")}>
                          <span>
                            {this.state.subMenuSelection === 1 && <img src={require('../icons/Summary-white.svg')} />}
                            {this.state.subMenuSelection !== 1 && <img src={require('../icons/Summary.svg')} />}
                          </span>
                        Summary
              </Button>
                      </div>
                      <div className="col-3 ff_click">
                        <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 2 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("financialsFees")}>
                          <span>
                            {this.state.subMenuSelection === 2 && <img src={require('../icons/Fee-white.svg')} />}
                            {this.state.subMenuSelection !== 2 && <img src={require('../icons/Fee.svg')} />}
                          </span>
                        Fees
              </Button>
                      </div>
                      <div className="col-3 fe_click">
                        <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 3 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("financialsExpenses")}>
                          <span>
                            {this.state.subMenuSelection === 3 && <img src={require('../icons/Expenses-white.svg')} />}
                            {this.state.subMenuSelection !== 3 && <img src={require('../icons/Expenses.svg')} />}
                          </span>
                        Expenses
              </Button>
                      </div>
                      <div className="col-3 fr_click">
                        <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 4 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("financialsReserves")}>
                          <span>
                            {this.state.subMenuSelection === 4 && <img src={require('../icons/Reserves-white.svg')} />}
                            {this.state.subMenuSelection !== 4 && <img src={require('../icons/Reserves.svg')} />}
                          </span>
                        Reserves
              </Button>
                      </div>
                    </div>
                    }
                    {this.state.mainSelection === 2 && <div className="row sub-row">
                      {/* <div className="col-4 bs_click">
                        <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 1 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("byLawsSummary")}>
                          <span>
                            {this.state.subMenuSelection === 1 && <img src={require('../icons/Summary-white.svg')} />}
                            {this.state.subMenuSelection !== 1 && <img src={require('../icons/Summary.svg')} />}
                          </span>
                        Summary
                </Button>

                      </div> */}

                      {
                        bylaws_g2k_na.length > 0 ? 

                        
                        <></>
                          :
                            (
                            <>
                            <div className="col-6 bm_click">
                                <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 2 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("byLawsMustKnow")}>
                                  Must know
                                </Button>
                          </div>

                          <div className="col-6 bn_click">
                                  <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 3 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("byLawsNiceToKnow")}>
                                    Good To know
                                  </Button>
                          </div>
                          </>
                          )
                  

                        } 





                    </div>
                    }
                    {this.state.mainSelection === 3 && <div className="row sub-row">
                      {/* <div className="col-4 m-auto">
                      <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 1 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("operationsSummary")}>
                        <span>
                          {this.state.subMenuSelection === 1 && <img src={require('../icons/Summary-white.svg')} />}
                          {this.state.subMenuSelection !== 1 && <img src={require('../icons/Summary.svg')} />}
                        </span>
                        Summary
                </Button>
                    </div> */}
                    </div>
                    }
                    {this.state.mainSelection === 4 && <div className="row sub-row">
                      {/* <div className="col-4 m-auto">
                        <Button variant="outlined" color="primary" className={this.state.subMenuSelection === 1 ? "sub-menu-active" : ""} onClick={() => this.onSubMenuChangeHandle("financingsSummary")}>
                          <span>
                            {this.state.subMenuSelection === 1 && <img src={require('../icons/Summary-white.svg')} />}
                            {this.state.subMenuSelection !== 1 && <img src={require('../icons/Summary.svg')} />}
                          </span>
                        Summary
                 </Button>
                      </div> */}
                    </div>
                    }
                  </div>
                }
                <div>
                  {(this.state.viewForm) ? this.state.detailForm : ''}
                </div>
              </div>
            </div>
            }
            {/* //End Desktop  View */}
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getReportData
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
