import React, { Component } from 'react';
import SemiCircle from '../charts/semiCircle';
import GroupedBarChart from '../charts/groupedBarChart';
import BasicColumnChart from '../charts/basicColumnChart';

class Reserves extends Component {

  constructor(props) {
    super(props);

    this.state = {};

    //BIND EVENTS
  }
  render() {
    const { reports } = this.props.graphData;
	let actualValue = reports[0].reserves_ihoa_funding
	let avg = 60
	
	let color=[]
	if (actualValue > .8*avg & actualValue < 1.2*  avg ){
        color = ['#5cd15d', '#bde12b']
    }
	else if (actualValue >=1.2*avg){
        //higher is worse
        color = ['#5cd15d', '#bde12b']
    }else if (actualValue <= .8*avg)
    {
		color = ['#ff8383', '#ffbfbf']
    }
    return (
      <section className="section animate-bottom px-0 mb-0">
        <div className="container">
          {/* <h4 className="text-center">{reports.map(item => item.reserves_headline)}
          </h4> */}
          <div className="row mb-4">
            <div className="col-12 col-lg-6 text-center">
              {/* <h3>Reserves adj by property size
            </h3> */}
              {/* {reports.map(item =>
                <SemiCircle key={1} dataSeries={[item.reserves_scaled_size, item.reserves_scaled_size_bm_min, item.reserves_scaled_size_bm_max]} />
              )} */}

              {reports.map(item =>
                <SemiCircle key={1} dataSeries={[item.reserves_ihoa_funding, 0, 100, 60,0]} />
              )}

              <div className="chart-bottom-text">
              <p className="mb-4" style={{ fontWeight: '600', fontSize: '60px' , margin: '-120px' }}>{reports.map(item =>
                      item.reserves_ihoa_funding
                    )}%</p>
                <p className="mb-0 mt-3" style={{ fontWeight: '600', fontSize: '14px' }}>{reports.map(item =>
                  item.reserves_grade
                )}</p>
                {reports.map((item) =>
                  item.reserves_comment!=null?item.reserves_comment
                    .split("\n")
                    .map((t, index) => {
                      return (
                        <p className="mt-4" key={index}>
                          {t}
                        </p>
                      );
                    }):''
                )}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              {/* <h2 className="text-center">
                Good to know
        </h2> */}
              <h4>{reports.map(item => item.reserves_headline)}
              </h4>
              <ul className="list-unstyled mt-4 mb-0">
                {reports.map(item =>
                  item.reserves_highlights!=null?item.reserves_highlights.split("\n").map((t, index) => {
                    return <li key={index} >{t}</li>;
                  }):''
                )}
              </ul>
            </div>
          </div>
          <div className="row mb-4 mt-5 fees-projection">
            <div className="col-12 col-md-6 text-center">

            <h3>Contribution to Reserves (% of HOA fees)
            </h3>
              {reports.map(item =>
                <GroupedBarChart key={1} dataSeries={[{ name: 'Your HOA', data: [item.reserves_contribution] }, { name: 'Average HOA', data: [item.reserves_contribution_bm] }]}
                  dataCategories={['']} colors={color}/>
              )}
{/* 
              {reports.map(item =>
                <GroupedBarChart key={1} dataSeries={[{ name: 'Your HOA', data: [item.reserves_ihoa_funding, 0] }, { name: 'Average HOA', data: [item.reserves_ihoa_funding_bm, 0] }]}
                  dataCategories={[['InspectHOA', 'method'], ['Reserves study', 'method']]} />
              )}
			  */}


            </div>
            <div className="col-12 col-md-6 text-center">
          
                <h3>Contribution to Reserves (% of Reserves)
            </h3>
               {reports.map(item =>
                <GroupedBarChart key={1} dataSeries={[{ name: 'Your HOA', data: [item.reserves_contribution_pct_reserves] }, { name: 'Average HOA', data: [item.reserves_contribution_pct_reserves_bm] }]} dataCategories={['Reserves grew by %']} colors={color}/>
              )} 
            </div>
          </div>

          <div className="row mb-4 mt-5 ">
          <div className="col-12 col-md-3">
            
            </div>
{/* 
            <div className="col-12 col-md-6 fees-projection text-center">
                
              <h3>Reserve Levels ($ adjusted by unit size)
            </h3>
              {reports.map(item =>
                <BasicColumnChart key={1} height={350}  dataLabels={true} width={'40%' } colors={color}
                  formatter={function(val) {
                    return val !== null ? "$"+ val.toLocaleString() : '' 
                  }}
                dataSeries={[{ name: 'Your HOA', data: [item.reserves_scaled_size] }, { name: 'Average HOA', data: [item.reserves_scaled_size_bm] }]}
                  dataCategories={[['', '']]} />
              )}
           </div> */}
            <div className="col-12 col-md-3">
            </div>

          </div>

        </div>

      </section>
    );
  }
}

export default Reserves;